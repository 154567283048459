import {Trans, useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import useAnimation from "../../utils/hooks/animate";
import Row from "./row";

import "./Download.css";

interface Description {
    LOCALE: string,
    VALUE1: string,
}

interface Version {
    VER?: string,
    SERVER_VER: string,
    CLIENT_VER: string,
    COMMENT: string,
    DESC?: Description[]
}

export const Download = () => {
    const {t} = useTranslation();
    useAnimation();
    useEffect(() => {
        document.title = t("title", {name: t("download")});
    }, [t]);

    const [version, setVersion] = useState({VER: "", SERVER_VER: " ", CLIENT_VER: " ", COMMENT: " ", DESC: []} as Version);
    useEffect(() => {
        fetch("/service/update/versions.json")
            .then(response => response.json())
            .then((jsonData) => {
                if (jsonData && Array.isArray(jsonData))
                    setVersion((jsonData as Version[])[0]);
            })
            .catch((error) => {
                console.error(error)
            });
    }, []);

    return (
        <main>
            <div className="d-flex flex-sm-row flex-column offset-lg-4 page-header download-header">
                <div className="text-header">{t("download.header.1")}</div>
                <div className="dash download-dash" id="download_dash_1"/>
            </div>
            <div className="download-section-1 offset-lg-2 col-lg-8 position-relative d-flex flex-column">
                {[
                    [t("download.header2.1"), "/download/ShelfWindowsSetup.exe"], // prettier-ignore
                    [t("download.header2.2"), "/download/shelf.zip"], // prettier-ignore
                ].map(([label, link], i) => (
                    <Row
                        key={i}
                        label={label}
                        link={link}
                        linkText={link.split("/").pop()!}
                    />
                ))}
                <div className="position-absolute download-part-1-2">
                    <div className="download-text-background part1">
                        {t("download.background.1")}
                    </div>
                    <div className="download-text-background part2">
                        {version.VER ? version.VER : version.SERVER_VER}
                    </div>
                </div>
            </div>
            <div className="d-flex flex-sm-row flex-column offset-lg-3 page-header download-header">
                <div className="text-header">{t("download.header.2")}</div>
                <div className="dash download-dash" id="download_dash_2"/>
            </div>
            <div className="offset-lg-2 col-lg-9 position-relative">
                <div className="download-section">
                    <div className="download-text-background download-section-back part3">
                        {t("download.background.3")}
                    </div>
                    {[
                        [t("download.header2.3"), "/download/java/examples.zip"], // prettier-ignore
                        [t("download.header2.4"), "/download/mavenRepository/ru/smcsystem/api/1.4.1/api-1.4.1.jar"], // prettier-ignore
                        [t("download.header2.5"), "https://github.com/pwipo/smc_api"], // prettier-ignore
                        [t("download.header2.6"), "/download/mavenRepository/ru/smcsystem/test-api/1.4.1/test-api-1.4.1.jar"], // prettier-ignore
                        [t("download.header2.7"), "https://github.com/pwipo/smc_java_test"], // prettier-ignore
                        [t("download.header2.8"), "/download/mavenRepository/ru/smcsystem/utils/1.3.3/utils-1.3.3.jar"], // prettier-ignore
                        [t("download.header2.9"), "https://github.com/pwipo/smc_java_utils"], // prettier-ignore
                        [t("download.header2.10"), "/download/mavenRepository/ru/smcsystem/archetype/1.4.1/archetype-1.4.1.jar"], // prettier-ignore
                        [t("download.header2.11"), "https://github.com/pwipo/smc_java_archetype"], // prettier-ignore
                        [t("download.header2.12"), "/download/mavenRepository/ru/smcsystem/smc-maven-plagin/1.0.0/smc-maven-plagin-1.0.0.jar"], // prettier-ignore
                        [t("download.header2.13"), "https://github.com/pwipo/smc_java_maven_plagin"], // prettier-ignore
                        [t("download.header2.14"), "https://github.com/pwipo/smc_java_modules"], // prettier-ignore
                    ].map(([label, link], i) => (
                        <Row
                            key={i}
                            label={label}
                            link={link}
                            linkText={link.split("/").pop()!}
                        />
                    ))}
                    <div className="dash download-long-dash"/>
                </div>
                <div className="download-section">
                    <div className="download-text-background download-section-back part4">
                        {t("download.background.4")}
                    </div>
                    {[
                        [t("download.header2.15"), "/download/python/examples.zip"], // prettier-ignore
                        [t("download.header2.16"), "/download/python/api.zip"], // prettier-ignore
                        [t("download.header2.17"), "https://github.com/pwipo/smc_api_python"], // prettier-ignore
                        [t("download.header2.18"), "/download/python/examples.zip"], // prettier-ignore
                        [t("download.header2.19"), "https://github.com/pwipo/smc_python_test"], // prettier-ignore
                        [t("download.header2.20"), "/download/python/utils.zip"], // prettier-ignore
                        [t("download.header2.21"), "https://github.com/pwipo/smc_python_utils"], // prettier-ignore
                        [t("download.header2.32"), "https://github.com/pwipo/smc_python_modules"], // prettier-ignore
                    ].map(([label, link], i) => (
                        <Row
                            key={i}
                            label={label}
                            link={link}
                            linkText={link.split("/").pop()!}
                        />
                    ))}
                    <div className="dash download-long-dash"/>
                </div>
                <div className="download-section">
                    <div className="download-text-background download-section-back part5">
                        <Trans i18nKey="download.background.5">
                            <br/>
                        </Trans>
                    </div>
                    {[
                        [t("download.header2.22"), "/download/js/examples.zip"], // prettier-ignore
                        [t("download.header2.23"), "/download/js/api.zip"], // prettier-ignore
                        [t("download.header2.24"), "https://github.com/pwipo/smc_api_js"], // prettier-ignore
                        [t("download.header2.25"), "/download/js/test.zip"], // prettier-ignore
                        [t("download.header2.26"), "https://github.com/pwipo/smc_js_test"], // prettier-ignore
                        [t("download.header2.27"), "/download/js/utils.zip"], // prettier-ignore
                        [t("download.header2.28"), "https://github.com/pwipo/smc_js_utils"], // prettier-ignore
                        [t("download.header2.33"), "https://github.com/pwipo/smc_js_modules"], // prettier-ignore
                    ].map(([label, link], i) => (
                        <Row
                            key={i}
                            label={label}
                            link={link}
                            linkText={link.split("/").pop()!}
                        />
                    ))}
                    <div className="dash download-long-dash"/>
                </div>
                <div className="download-section download-section-last">
                    <div className="download-text-background download-section-back part6">
                        {t("download.background.6")}
                    </div>
                    {[
                        [t("download.header2.29"), "/download/cpp/examples.zip"], // prettier-ignore
                        [t("download.header2.30"), "/download/cpp/api.zip"], // prettier-ignore
                        [t("download.header2.31"), "https://github.com/pwipo/smc_api_cpp"], // prettier-ignore
                        [t("download.header2.34"), "https://github.com/pwipo/smc_cpp_modules"], // prettier-ignore
                    ].map(([label, link], i) => (
                        <Row
                            key={i}
                            label={label}
                            link={link}
                            linkText={link.split("/").pop()!}
                        />
                    ))}
                </div>
            </div>
        </main>
    );
};
